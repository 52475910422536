<template>
  <section
    class="
      flex
      max-w-full
      gap-6
      px-6
      py-10
      overflow-x-scroll
      scrollbar-hide
      lg:gap-10 lg:justify-center lg:pl-0 lg:py-14
    "
    :class="[backgroundColor, textColor]"
  >
    <CategoryItem
      v-for="(item, idx) in items"
      :key="idx"
      v-bind="item"
      v-editable="item"
    />
  </section>
</template>

<script>
import CategoryItem from '~/components/category-item/CategoryItem'

export default {
  name: 'CategorySlider',
  components: {
    CategoryItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'bg-white',
    },
    textColor: {
      type: String,
      default: 'text-black',
    },
  },
}
</script>
