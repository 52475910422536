<template>
  <mp-link
    category-item
    :to="$u(link)"
    class="
      flex flex-col
      items-center
      justify-start
      gap-3
      group
      min-w-[72px] 
      lg:w-[96px]
    "
  >
    <picture
      class="
        w-[72px]
        h-[72px]
        min-w-[72px] min-h-[72px]
        lg:w-[96px] lg:h-[96px]
        flex
        items-center
        justify-center
        p-2
        rounded-full
        bg-lightGray
        group-hover:bg-gray
      "
    >
      <Vue2Image
        class="object-contain w-full h-full transition rounded-full"
        :src="image.filename"
        :alt="title"
        :widths="[70]"
        :width="70"
        format="png"
      />
    </picture>
    <span class="
      text-xs
      tracking-widest
      uppercase
      lg:text-sm
      hover:underline
      underline-offset-4 
      text-center
    ">{{ title }}</span>
  </mp-link>
</template>

<script>
import Vue2Image from '@made-people/vue2-image'
export default {
  name: 'CategoryItem',
  components: {
    Vue2Image,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    image: {
      type: Object,
      default: () => {},
    },
    link: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
